<template>
  <div class="calendar__data-range-wrap" v-click-outside="closeCalendar">
    <div class="calendar__data-range" @click="toggleCustomPeriodsCalendar">
      Date range
      <SelectArrow
        :class="{ isOpenSelectArrow: isCustomPeriodsCalendarVisible }"
      />
    </div>
    <div
      class="calendar__custom-settings-wrap"
      v-if="isCustomPeriodsCalendarVisible"
    >
      <div @click="handleStaticRange(1)">Last 7 days</div>
      <div @click="handleStaticRange(2)">Last 30 days</div>
      <div @click="handleStaticRange(3)">All time</div>
      <div class="calendar__custom-range" @click="toggleCalendar">
        Custom Range
      </div>
    </div>
    <Calendar
      class="calendar__calendar"
      :select-date-fill="selectDateFill"
      v-show="isCalendarVisible"
    />
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import Calendar from "@/components/molecules/Calendar.vue";

export default {
  props: {
    selectDateFill: Function,
    onSelectStaticRange: Function,
  },
  data() {
    return {
      isCustomPeriodsCalendarVisible: false,
      isCalendarVisible: false,
    };
  },
  components: {
    SelectArrow,
    Calendar,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    toggleCustomPeriodsCalendar() {
      this.isCustomPeriodsCalendarVisible =
        !this.isCustomPeriodsCalendarVisible;
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCustomPeriodsCalendarVisible = false;
      this.isCalendarVisible = false;
    },
    handleStaticRange(option) {
      this.$emit("onSelectStaticRange", option);
      this.closeCalendar();
    },
  },
};
</script>
