
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import AnaliticMenuSVG from "@/assets/svg/analitic-menu.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import AnalyticsNavMenu from "@/components/molecules/AnalyticsNavMenu.vue";
import Select from "@/components/atoms/CustomSelect.vue";
import Calendar from "@/components/molecules/Calendar.vue";
import DateRangePicker from "@/components/molecules/DateRangePicker.vue";
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  name: "Instructors",
  data() {
    return {
      page: 1,
      instructorsData: [
        {
          countProgres: 10,
        },
        {
          countProgres: 60,
        },
      ],
      instructorsMeta: null,
      errorInstructors: null,
      searchData: "",
      sortData: "",
      isModalDelete: false,
      idInstructor: null,
      dataAllInstructors: [],
      dataSkills: [],
      dataLevels: [],
      selectedInstructor: false,
      isCalendarVisible: false,
      analyticsData: {},
      analyticsMeta: null,
      erroranAlytics: null,
      filters: "",
      filtersDate: "",
      filtersLevel: "",
      filtersScills: "",
      dataAllInstructorStudents: {},
      instructorNameSelected: "",
      instructorId: "",
      activeItemId: null,
      instructorName: "All Instructors",
      isMenuActive: false,
      period: 1,
    };
  },
  components: {
    AdminPanelNavMenu,
    AnalyticsNavMenu,
    SortArrow,
    DefaultPopup,
    AlertBlock,
    Pagination,
    Select,
    Calendar,
    SelectArrow,
    AnaliticMenuSVG,
    DateRangePicker,
  },
  watch: {
    searchData() {
      this.getInstructors();
      this.page = 1;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.getAnalytics();
    this.getInstructors();
    this.getLevels();
    this.getSkills(1);
  },
  methods: {
    getAnalytics(params, sort) {
      let searchParam = `?page=${this.page}&n=20`;
      let startDate = "";
      let endDate = "";

      const currentDate = new Date();
      const pastDate = new Date();
      if (this.period === 1) {
        pastDate.setDate(currentDate.getDate() - 7);
      } else if (this.period === 2) {
        pastDate.setDate(currentDate.getDate() - 30);
      } else if (this.period === 3) {
        pastDate.setDate(currentDate.getDate() - 1000);
      }

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      if (this.searchData !== "") {
        searchParam = `?page=${this.page}` + this.searchData;
      }

      if (sort === undefined) {
        sort = "";
      }

      startDate =
        this.selectedStartDate !== undefined
          ? this.selectedStartDate
          : formatDate(pastDate);
      endDate =
        this.selectedEndDate !== undefined
          ? this.selectedEndDate
          : formatDate(currentDate);

      params =
        searchParam +
        sort +
        this.filters +
        this.filtersDate +
        this.filtersLevel +
        this.filtersScills +
        `&start_date=${startDate}` +
        `&end_date=${endDate}`;

      this.$store.dispatch("analytics/skillsTracking", params).then(
        (res) => {
          this.analyticsData = res.data;
          this.analyticsMeta = res.meta;
          this.getInstructorStudents(
            this.analyticsData[0].id,
            this.analyticsData[0].full_name
          );
        },
        (error) => {
          this.erroranalytics = error.response.data.errors;
        }
      );
    },
    getInstructors() {
      const params = "?n=100";
      this.$store.dispatch("instructors/getInstructors", params).then(
        (res) => {
          this.dataAllInstructors = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getInstructorStudents(id, name, sort) {
      let filterScills = "";
      let filterLevel = "";
      let startDate = "";
      let endDate = "";

      const currentDate = new Date();
      const pastDate = new Date();
      if (this.period === 1) {
        pastDate.setDate(currentDate.getDate() - 7);
      } else if (this.period === 2) {
        pastDate.setDate(currentDate.getDate() - 30);
      } else if (this.period === 3) {
        pastDate.setDate(currentDate.getDate() - 1000);
      }

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      if (this.filtersScills === "&filter[skill_id]=undefined") {
        filterScills = "";
      } else filterScills = this.filtersScills;

      if (this.filtersLevel === "&filter[level_id]=undefined") {
        filterLevel = "";
      } else filterLevel = this.filtersLevel;

      this.activeItemId = id;
      this.instructorId = id;

      startDate =
        this.selectedStartDate !== undefined
          ? this.selectedStartDate
          : formatDate(pastDate);
      endDate =
        this.selectedEndDate !== undefined
          ? this.selectedEndDate
          : formatDate(currentDate);

      if (sort === undefined) {
        sort = "";
      }

      const params = {
        id: id,
        filters: `?${filterLevel}&${filterScills}&`,
        sort: `${sort}`,
        start_date: `start_date=${startDate}`,
        end_date: `&end_date=${endDate}`,
      };

      this.$store.dispatch("instructors/getInstructorStudents", params).then(
        (res) => {
          this.instructorNameSelected = name;
          this.dataAllInstructorStudents = res.data;
          this.selectedInstructor = true;
          console.log("instructorNameSelected", this.instructorNameSelected);
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getLevels() {
      this.$store.dispatch("general/getLevels").then(
        (res) => {
          this.dataLevels = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    getSkills(id) {
      this.$store.dispatch("general/getSkillsByLevels", id).then(
        (res) => {
          this.dataSkills = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getInstructors();
    },
    handleOptionSelected(filterKey, selectedOption, callback) {
      const newFilter = `filter[${filterKey}]=${selectedOption.id}`;
      const regex = new RegExp(`filter\\[${filterKey}\\]=[^&]*`);
      if (selectedOption.id !== undefined) {
        if (regex.test(this.filters)) {
          this.filters = this.filters.replace(regex, newFilter);
        } else {
          this.filters += `${
            this.filters
          }&${newFilter}`;
        }
      } else
        this.filters = this.filters
          .replace(regex, "")
          .replace(/&{2,}/g, "&")
          .replace(/[?&]$/, "");
      if (callback) callback(selectedOption.id);
      this.getAnalytics();
    },
    handleOptionLevelSelected(selectedOption) {
      this.getSkills(selectedOption.id);
      this.filtersLevel = `&filter[level_id]=${selectedOption.id}`;
      if (selectedOption.id !== undefined) {
        this.getInstructorStudents(
          this.instructorId,
          this.instructorNameSelected
        );
      }
    },
    handleOptionLevelSkillsSelected(selectedOption) {
      this.getSkills(selectedOption.id);
      this.filtersLevel = `&filter[level_id]=${selectedOption.id}`;
      if (selectedOption.id !== undefined) {
        this.getAnalytics();
      }
    },
    handleOptionSkillsSelected(selectedOption) {
      this.filtersScills = `&filter[skill_id]=${selectedOption.id}`;
      if (selectedOption.id !== undefined) {
        this.getAnalytics();
      }
    },
    selectDateFill(date, month, year) {
      console.log("aaaa", date);
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`;
        this.getAnalytics();
        this.getInstructorStudents(this.instructorId);
      }
    },
    resetFilters() {
      window.location.reload();
    },
    resetStidentsFilters() {
      this.filtersScills = "";
      this.filtersLevel = "";
      this.getInstructorStudents(
        this.instructorId,
        this.instructorNameSelected
      );
      this.clearSelection();
    },
    clearSelection() {
      if (this.$refs.customSelectRefLevel) {
        this.$refs.customSelectRefLevel.clearSelected();
      }
      if (this.$refs.customSelectRefSkills) {
        this.$refs.customSelectRefSkills.clearSelected();
      }
    },
    clearSelected() {
      console.error();
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    closeMenu() {
      this.isMenuActive = false;
    },
    closeCalendar() {
      this.isCustomPeriodsCalendarVisible = false;
      this.isCalendarVisible = false;
    },

    selectStaticRange(period) {
      this.period = period;
      this.getAnalytics();
    },
  },
});
